import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    component: () => import("../views/layout/LoggedInLayout.vue"),
    children: [
      {
        title: "Claims",
        path: "",
        name: "Claims",
        component: () => import("../views/claims/Claims.vue"),
      },
      {
        title: "Claim",
        path: "/:id/:client_id",
        name: "Claim",
        component: () => import("../views/claims/Claim.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.auth.status.loggedIn) {
    console.info("Not logged in and not loading login page, redirecting!");
    return next({ name: "Login" });
  }

  next();
});

export default router;
